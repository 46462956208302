import React, {useContext, useEffect, useState} from 'react';
import StatisticsPanelComponent from "../components/StatisticsPanelComponent";
import {Page} from "../components/global/global";
import AddPoolModal from "../components/modal/AddPoolModal";
import WalletsComponent from "../components/WalletsComponent/WalletsComponent";
import AddWalletModal from "../components/modal/AddWalletModal";
import {
    IBotsGetResponse,
    IWalletsGetPoolsResponse,
    IWalletsGetRequest,
    IWalletsGetResponse
} from "../api/service/models";
import {useQuery} from "react-query";
import WalletsApi from "../api/service/WalletsApi";
import PoolsComponent from "../components/PoolsComponent/PoolsComponent";
import BotsApi from "../api/service/BotsApi";
import BotsComponent from "../components/BotsComponent/BotsComponent";
import AddBotModal from "../components/modal/AddBotModal";
import StatsApi from "../api/service/StatsApi";
import UserStorage from "../storage/UserStorage/UserStorage";


export const DashboardPage: React.FC = () => {

    const [wallets, setWallets] = useState<IWalletsGetResponse | null>(null);
    const walletsItems = wallets?.items || [];
    const [pools, setPools] = useState<IWalletsGetPoolsResponse | null>(null);
    const poolsItems = pools?.items || [];
    const [bots, setBots] = useState<IBotsGetResponse | null>(null);
    const botsItems = bots?.items || [];

    const UserStore = useContext(UserStorage);
    const isAdmin = UserStore?.isAdmin()
    const user = UserStore?.user

    const [addPoolMenu, setAddPoolMenu] = useState(false);
    const handleAddWalletPoolMenu = () => setAddPoolMenu(open => !open);
    const [addWalletMenu, setAddWalletMenu] = useState(false);
    const handleAddWalletMenu = () => setAddWalletMenu(open => !open);
    const [createBotMenu, setCreateBotMenu] = useState(false);
    const handleCreateBotMenu = () => setCreateBotMenu(open => !open);

    const walletsReq: IWalletsGetRequest = {
        pageSize: 30,
        pageNumber: 1
    };

    const getWalletsQuery = useQuery('get_wallets', () => WalletsApi.getWallets(walletsReq), {
        onSuccess: data => {
            setWallets(data);
        },
        refetchInterval: 60000
    });
    const getPoolsQuery = useQuery('get_pools', () => WalletsApi.getPools(walletsReq), {
        onSuccess: data => {
            setPools(data);
        }
    });
    const getBotsQuery = useQuery('get_bots', () => BotsApi.getBots(walletsReq), {
        onSuccess: data => {
            setBots(data);
        },
        refetchInterval: 60000
    });

    const fetchGeneralStats = async (botId) => StatsApi.dailyGeneral({ botId });
    const fetchAllData = async () => {
        const generalStatsPromises = botsItems?.map((bot) => fetchGeneralStats(bot.id));
        const generalStats = await Promise.all(generalStatsPromises);
        return { generalStats };
    };

    const { data: generalStatsData, isLoading, refetch } = useQuery(['get_general_stats', botsItems], fetchAllData, {

    });

    useEffect(() => {
        if(botsItems.length > 0) {
            refetch();
        }
    }, [botsItems, refetch]);

    const totalVolumeSum = generalStatsData?.generalStats?.reduce((sum, item) => sum + item.totalVolumeInUSD, 0)?.toFixed(2)
    const statisticsData = [
        {value: totalVolumeSum, title: "Total USD Volume"},
    ];

    // TODO: add loading anim

    return (
        <Page>
            {pools && <AddBotModal openMenu={createBotMenu} handleMenu={handleCreateBotMenu} pools={poolsItems}/>}
            <AddWalletModal openMenu={addWalletMenu} handleMenu={handleAddWalletMenu}/>
            <AddPoolModal openMenu={addPoolMenu}
                                 handleMenu={handleAddWalletPoolMenu}
                                 wallets={walletsItems}/>
            <StatisticsPanelComponent stats={[statisticsData.slice(0, 2), statisticsData.slice(2, 4), statisticsData.slice(4, 6)]}/>
            <WalletsComponent wallets={walletsItems} openMenu={handleAddWalletMenu}/>
            <PoolsComponent pools={poolsItems} openMenu={handleAddWalletPoolMenu} wallets={walletsItems}/>
            <BotsComponent bots={botsItems} openMenu={handleCreateBotMenu} pools={poolsItems}/>
        </Page>
    );
};

export default DashboardPage;

