import React, {useContext, useEffect} from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import {
    FlexColumn,
} from "./components/global/global";
import  AuthPage from "./pages/AuthPage";
import DashboardPage from './pages/DashboardPage';
import AppProvider from "./AppProvider";
import UserStorage from "./storage/UserStorage/UserStorage";
import BigNumber from "bignumber.js";
import {observer} from "mobx-react-lite";
import {useMutation} from "react-query";
import AuthApi from "./api/service/AuthApi";
import {jwtDecode} from "jwt-decode";
import {colors} from "./config/colors";


const AppStyle = styled(FlexColumn)`
  background: ${colors.grayM200};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  color: #fff;
  overflow: hidden;
`;

function App() {

    const UserStore = useContext(UserStorage);
    const isAuth = !!UserStore.accessToken;

    BigNumber.config({
        EXPONENTIAL_AT: 1000,
        DECIMAL_PLACES: 80,
    });

    const store = useContext(UserStorage);
    const refreshMutation = useMutation({
        mutationFn: () => {
            const params = {
                accessToken: store.accessToken,
                refreshToken: store.refreshToken,
            }
            return AuthApi.refreshToken(params);
        },
        onSuccess: (data) => {
            store.setTokens({ accessToken: data.accessToken, refreshToken: data.refreshToken });
            store.setUser(jwtDecode(data.accessToken))
            store.setIsAuth(true);
        },
        onError: error => {
            store.logout();
        },
    });

    useEffect(() => {
        if (store.isAuth &&
            new Date().getTime() / 1000 >= store?.user?.exp &&
            ((new Date().getTime() - new Date(store?.user?.iat).getTime()) / 1000 / 60 / 60 / 24) <= 120
        ) {
            refreshMutation.mutate();
        }
    }, []);

    return (
        <AppProvider>
            <AppStyle>
                <BrowserRouter>
                    <Routes>
                    {isAuth ?
                            <>
                                <Route path="/" Component={DashboardPage}/>
                                <Route path="/*" element={<Navigate to={'/'} replace/>}/>
                            </>
                        :
                            <>
                                <Route path="/login" Component={AuthPage}/>
                                <Route path="/*" element={<Navigate to={'/login'} replace/>}/>
                            </>
                    }
                    </Routes>
                </BrowserRouter>
            </AppStyle>
        </AppProvider>
    );
}

export default observer(App);

