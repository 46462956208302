import {computed, action, autorun, makeAutoObservable} from "mobx";
import {createContext} from "react";
import {ITokens} from "../../api/service/models";

class UserStorage {

    user: any | null = localStorage.user ? JSON.parse(localStorage.user) : null;
    accessToken: string | null = localStorage.accessToken ? JSON.parse(localStorage.accessToken) : null;
    refreshToken: string | null = localStorage.refreshToken ? JSON.parse(localStorage.refreshToken) : null;
    isAuth: boolean | null = localStorage.isAuth ? JSON.parse(localStorage.isAuth) : false;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
        autorun(async () => {
            localStorage.user = JSON.stringify(this.user)
            localStorage.accessToken = JSON.stringify(this.accessToken)
            localStorage.refreshToken = JSON.stringify(this.refreshToken)
            localStorage.isAuth = JSON.stringify(this.isAuth)
        })
    }

    @computed isAdmin() {
        const res = this.user ? this.user['UserRole']?.includes('Admin') : false
        return res
    }

    @action setUser(data: any) {
        this.user = data;
    }

    @action setIsAuth(status: boolean) {
        this.isAuth = status;
    }

    @action logout() {
        localStorage.clear();
    }

    @action setTokens(response: ITokens) {
        this.accessToken = response.accessToken;
        this.refreshToken = response.refreshToken;
    }

}

export default createContext(new UserStorage());
