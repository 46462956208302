import React, {useContext} from "react";
import {
    Table,
    TopValueWrapper,
    ValueWrapper
} from "../global/global";
import BlockTitleWithButton from "../BlockTitleWithButton";
import {IPool, IWallet, IWalletsUpdateRequest} from "../../api/service/models";
import PoolComponent from "./components/PoolComponent";
import GlobalModalStore from "../../storage/GlobalModalStore/GlobalModalStore";
import {useMutation, useQueryClient} from "react-query";
import WalletsApi from "../../api/service/WalletsApi";


interface PoolsComponentProps {
    pools: IPool[];
    openMenu: () => void;
    wallets: IWallet[];
}

const PoolsComponent: React.FC<PoolsComponentProps> = ({pools, openMenu, wallets}) => {

    const GlobalModalStorage = useContext(GlobalModalStore);
    const queryClient = useQueryClient();

    const removePoolMutation = useMutation((data: IWalletsUpdateRequest) => WalletsApi.removePool(data), {
        onError: error => {
            GlobalModalStorage.makeVisible(false, "Пул не удален");
        },
        onSuccess: data => {
            GlobalModalStorage.makeVisible(true, "Пул удален");
            queryClient.invalidateQueries('get_pools');
        },
    });

    const removePool = (id: number) => {
        removePoolMutation.mutate({id: id});
    };

    return (
        <TopValueWrapper style={{width: "100%"}}>
            <ValueWrapper style={{height: "auto", width: "100%", alignItems: "start"}}>
                <BlockTitleWithButton title="Wallets pools" actionTitle="Create Pool" action={openMenu}/>
                <Table>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    {pools && pools.map((pool: IPool) => <PoolComponent id={pool.id}
                                                                        name={pool.name}
                                                                        wallets={wallets}
                                                                        poolWallets={pool.wallets}
                                                                        removePool={removePool}/>)}
                </Table>
            </ValueWrapper>
        </TopValueWrapper>
    );
};


export default PoolsComponent;